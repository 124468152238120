<template>
  <div class="frames_top">
    <div class="top_img_div">
      <div class="logo_img" @click="toColumn(0)" style="cursor: pointer;">
        <img src="../../assets/images/tfi.png">
        <div class="text_img mobHide">Booster</div>
      </div>
    </div>
    <div class="top_tools">
      <div class="top_trust_fi mobHide mobHide" @click="openUrl('https://www.trustfi.org/')">
        Home
      </div>
      <div class="top_my_nft mobHide" @click="toColumn(1)">
        <div class="nft_text">
          My NFT
        </div>
        <div class="new_text">
          New
        </div>
      </div>
      <div class="top_projects mobHide" @click="toColumn(2)">
        Projects
      </div>

      <div class="top_bnb">
        <el-menu :default-active="activeIndex"
                 class="el-menu-demo"
                 background-color="black"
                 mode="horizontal"
                 @select="handleSelect">
          <el-submenu index="1">
            <template slot="title">
              <img v-if="tokenChainType === 'BSC'" class="type_logo" src="../../assets/images/coin-bnb-logo.png"/>
              <img v-if="tokenChainType === 'ETH'" class="type_logo" src="../../assets/images/eth_logo.png"/>
              <img v-if="tokenChainType === 'AVAX'" class="type_logo" src="../../assets/images/avax_logo.png"/>
              <img v-if="tokenChainType === 'POLYGON'" class="type_logo" src="../../assets/images/polygon_logo.png"/>
            </template>
            <el-menu-item index="BSC"><img class="type_logo" src="../../assets/images/coin-bnb-logo.png"/></el-menu-item>
            <el-menu-item index="ETH"><img class="type_logo" src="../../assets/images/eth_logo.png"/></el-menu-item>
            <el-menu-item index="AVAX"><img class="type_logo" src="../../assets/images/avax_logo.png"/></el-menu-item>
            <el-menu-item index="POLYGON"><img class="type_logo" src="../../assets/images/polygon_logo.png"/></el-menu-item>
          </el-submenu>
        </el-menu>
<!--        <img src="../../assets/images/coin-bnb-logo.png">-->
      </div>

      <div class="top_connect" @click="showConnectWallet()">
        {{ $store.state.accounts.length === 0 ? anniuText : cutAccount($store.state.accounts[0], 4) }}
      </div>
      <div class="mobMenuIco">
        <img @click="menuShow = !menuShow" src="../../assets/images/headNav.png"/>
        <div v-show="menuShow" class="mobMenuContent">
          <div @click="toColumn(0)"> Why TrustFi Booster?</div>
          <div @click="toColumn(1)"> My NFT</div>
          <div @click="toColumn(2)"> Projects</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {cutAccount} from "../../tools";
import transfer from "../../tools/transfer";

export default {
  name: "top",
  data() {
    return {
      activeIndex: '1',
      tokenChainType: 'BSC',
      anniuText: "Connect",
      drawer: false,
      menuShow: false
    };
  },
  watch: {},
  created() {
  },
  mounted() {
    this.initChainType()
  },
  beforeDestroy() {
  },
  methods: {
    openUrl(url) {
      window.location.href = url;
    },
    handleSelect(key, keyPath) {
      this.tokenChainType = key

      if (sessionStorage.getItem('tokenChainType') && sessionStorage.getItem('tokenChainType') !== key) {
        this.hide();
        this.$store.dispatch('logOut');

        var text = 'You have switched to the ' + key + ' page, please switch to the ' + key + ' network and reconnect your wallet!';
        this.$message.warning(text);

        sessionStorage.setItem('tokenChainType', key);

        transfer.$emit('tokenChainRefresh');
      } else {
        sessionStorage.setItem('tokenChainType', key);
      }

      console.log(keyPath);
    },
    initChainType() {
      if (sessionStorage.getItem('tokenChainType')) {
        this.tokenChainType = sessionStorage.getItem('tokenChainType');
      } else {
        this.tokenChainType = 'BSC';
        sessionStorage.setItem('tokenChainType', 'BSC');
      }
    },
    hide() {
      this.$store.commit('setState', {
        connectWalletShow: false
      })
    },
    showConnectWallet() {
      this.$store.commit('setState', {
        connectWalletShow: true
      })
    },
    cutAccount(account, num) {
      return cutAccount(account, num);
    },
    toColumn(urlIndex) {
      sessionStorage.setItem('urlIndex', urlIndex)
      if (urlIndex === 0) {
        this.$router.push("/ ");
      } else if (urlIndex === 1) {
        this.$router.push("/nft");
      } else if (urlIndex === 2) {
        this.$router.push("/pools");
      }
    }
  }
}
</script>

<style lang="less">
.frames_top {
  position: fixed;
  top: 0;
  z-index: 10;
  height: 100px;
  width: 100%;
  min-width: 1080px;
  color: #FFFFFF;
  background: black;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  .top_img_div {
    .logo_img {
      /*margin-left: 50px;*/
      margin-left: 80px;

      img {
        width: 240px;
        height: 80px;
      }

      .text_img {
        position: absolute;
        width: 58px;
        height: 18px;
        left: 223px;
        top: 79px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: black;
        background: #FEE108;
        border-radius: 2px;
      }
    }
  }

  .top_tools {
    margin-right: 45px;
    height: 100px;
    width: 800px;
    min-width: 700px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;

    .top_trust_fi {
      cursor: pointer;
    }

    .top_my_nft {
      cursor: pointer;

      .nft_text {

      }

      .new_text {
        position: absolute;
        width: 35px;
        height: 16px;
        font-size: 13px;
        line-height: 16px;
        font-weight: 600;
        text-align: center;
        color: black;
        box-sizing: border-box;
        box-shadow: 0px 0px 15px #FEE108;
        background: #FEE108;
        border-radius: 2px;
      }
    }

    .top_projects {
      cursor: pointer;
    }

    .top_bnb {
      cursor: pointer;

      img {
        width: 40px;
      }
    }

    .top_connect {
      cursor: pointer;
      width: 160px;
      height: 50px;
      font-size: 20px;
      text-align: center;
      line-height: 45px;
      color: black;
      background: #FEE108;
      border: 1px solid #FEE108;
      box-sizing: border-box;
      box-shadow: 0px 0px 18px #FEE108;
      border-radius: 10px;
    }
  }
}

.mobMenuIco {
  display: none;
}



.el-menu-demo {
  border: 0px solid black !important;
}

.el-menu--horizontal {
  display: block;

  width: 90px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #313131;
  ul {
    width: 90px;
    li {
      height: 55px !important;
      line-height: 55px !important;
    }
    li:hover {
      background-color: #404040 !important;
    }
  }
}

.el-submenu__title:hover {
  background-color: black !important;
}

.el-submenu.is-active .el-submenu__title {
  border-bottom-color: black !important;
}

.el-menu.el-menu--horizontal {
  border-bottom: solid 1px black !important;
}

.el-menu--collapse .el-menu .el-submenu, .el-menu--popup {
  min-width: 90px !important;
}

.el-menu {
  .type_logo {
    width: 40px !important;
    height: 40px !important;
  }
}

.el-message {
  top: 100px !important;
}

@import '../../global.less';

</style>
