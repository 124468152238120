<template>
  <div class="wrapper" ref="currentElement">
    <div class="column" style="width: 315px; padding-top: 36px">
      <img class="main-logo" src="../../assets/images/footer/main_logo.png" />
      <div style="height: 20px" />
      <div class="appendix">
        TrustFi focuses on early crypto assets issuance, liquidity management,
        community activities and DAO governance to unlock the potential of DeFi.
      </div>
      <div style="height: 32px" />
      <div class="sub-title">Buy $TFI</div>
      <div style="height: 6px" />
      <div
        class="sub-button"
        @click="
          openUrl(
            'https://pancakeswap.finance/swap?outputCurrency=0x7565ab68d3f9dadff127f864103c8c706cf28235'
          )
        "
      >
        <img src="../../assets/images/footer/dealer_icon_1_1.png" height="23" />
        <div>TFI/BNB</div>
      </div>
      <div
        class="sub-button"
        style="bottom: calc(-200% - 6px)"
        @click="openUrl('https://www.mexc.com/exchange/TRUSTFI_USDT')"
      >
        <img src="../../assets/images/footer/dealer_icon_2.png" height="21" />
        <div>TRUSTFI/USDT</div>
      </div>
      <div
        class="sub-button"
        style="bottom: calc(-300% - 8px)"
        @click="openUrl('https://www.hoo.com/spot/tfi-usdt')"
      >
        <img src="../../assets/images/footer/dealer_icon_3.png" height="30" />
        <div>TFI/USDT</div>
      </div>
    </div>
    <div class="column" style="width: 200px">
      <div class="title">About Us</div>
      <div style="height: 20px" />
      <div class="link-text" @click="openUrl('https://docs.trustfi.org/')">
        Whitepaper
      </div>
      <div
        class="link-text"
        @click="openUrl('https://www.certik.com/projects/trustfinetwork')"
      >
        Smart Contract Audits
      </div>
      <div class="link-text" @click="openUrl('https://trustfi.medium.com/')">
        Blog
      </div>
      <div
        class="link-text"
        @click="
          openUrl(
            'https://medium.com/trustfi/attention-trustfi-is-hiring-435118c385fa'
          )
        "
      >
        Join Us
      </div>
    </div>
    <div class="column" style="width: 330px">
      <div class="title">Telegram Community</div>
      <div style="height: 20px" />
      <div class="link-text" @click="openUrl('https://t.me/trustfi_fans')">
        <img
          class="nation"
          src="../../assets/images/footer/icon_united_states.png"
        />
        English Community
      </div>
      <div class="link-text" @click="openUrl('https://t.me/TrustFiTurkey')">
        <img
          class="nation"
          src="../../assets/images/footer/icon_turkey.png"
        />Turkish Community
      </div>
      <div class="link-text" @click="openUrl('https://t.me/TrustFiIDN')">
        <img
          class="nation"
          src="../../assets/images/footer/icon_indonesia.png"
        />Indonesian Community
      </div>
      <div class="link-text" @click="openUrl('https://t.me/tfivngroup')">
        <img
          class="nation"
          src="../../assets/images/footer/icon_vietnam.png"
        />Vietnamese Community
      </div>
      <div
        class="link-text"
        @click="openUrl('https://open.kakao.com/o/grQCY7Kd')"
      >
        <img
          class="nation"
          src="../../assets/images/footer/icon_south_korea.png"
        />Korean Community (Kakao)
      </div>
      <div class="link-text" @click="openUrl('https://t.me/trustfi_francais')">
        <img
          class="nation"
          src="../../assets/images/footer/icon_france.png"
        />French Community
      </div>
      <div class="link-text" @click="openUrl('https://t.me/trustfichinese')">
        <img
          class="nation"
          src="../../assets/images/footer/icon_china.png"
        />Chinese Community
      </div>
    </div>
    <div class="column" style="width: 350px">
      <div class="title">Our Social Network</div>
      <div style="height: 20px" />
      <div class="link-text" @click="openUrl('https://twitter.com/trustfiorg')">
        <img
          class="platform"
          src="../../assets/images/footer/icon_twitter.png"
        />TrustFi on Twitter
      </div>
      <div class="link-text" @click="openUrl(' https://medium.com/@trustfi')">
        <img
          class="platform"
          src="../../assets/images/footer/icon_medium.png"
        />TrustFi on Medium
      </div>
      <div
        class="link-text"
        @click="openUrl('https://www.linkedin.com/company/trustfi')"
      >
        <img
          class="platform"
          src="../../assets/images/footer/icon_linkedin.png"
        />TrustFi on Linkedin
      </div>
      <div class="link-text" @click="openUrl('https://t.me/trustfi_channel')">
        <img
          class="platform"
          src="../../assets/images/footer/icon_telegram.png"
        />TrustFi on Telegram
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "FooterV3",
  components: {
  },
  data() {
    return {
      showCopyEmailPopup: false,
    };
  },
  methods: {
    openUrl(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  position: relative;
  height: 562px;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  color: white;

  .column {
    padding-top: 88px;

    .appendix {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: #ffffff;
    }

    .title {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 35px;
      line-height: 41px;
      white-space: nowrap;
    }

    .sub-title {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 29px;
      color: #ffffff;
    }

    .sub-button {
      width: 250px;
      height: 63px;
      border: 2px solid #fee108;
      box-sizing: border-box;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: white;
      text-align: center;
      margin-top: 8px;
      cursor: pointer;
    }

    .link-text {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: #ffffff;
      margin-bottom: 10px;
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .platform {
        width: 23px;
        height: 23px;
        margin-right: 4px;
      }

      .nation {
        width: 28px;
        height: 24px;
        margin-right: 4px;
      }
    }
  }
}
</style>
