<template>
    <div class="tfi_frames">
        <Top/>
        <Popup/>
<!--        <Bottom/>-->
    </div>
</template>

<script>
    import Popup from "../../components/popup/Index";
    import Top from "./Top";
    import Bottom from "./Bottom";

    export default {
        name: "index",
        components: {Popup, Top, Bottom}
    }
</script>

<style lang="less">
  body{
    background: #000000;
    padding-top: 100px;
  }
    .tfi_frames {

    }
</style>
