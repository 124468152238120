<template>
    <div class="frames_bottom">
        <div class="bottom_tfi">
            <div class="tfi_div_img">
                <img src = "../../assets/images/tfi.png"/>
                <div class="tfi_div_text">
                    Booster
                </div>
            </div>
        </div>

        <div class="bottom_tb_div">
            <div class="tb_div_shang">
                <a>
                    <div class="huang_yuan">
                        <img class="huang_yuan_tubiao_a" src="../../assets/images/github.png"/>
                    </div>
                </a>
                <a>
                    <div class="huang_yuan">
                        <img class="huang_yuan_tubiao_a" src="../../assets/images/twitter.png"/>
                    </div>
                </a>
                <a>
                    <div class="huang_yuan">
                        <img class="huang_yuan_tubiao_b" src="../../assets/images/xa.png"/>
                        <img src="../../assets/images/nft/check.png"/>
                    </div>
                </a>
            </div>
            <div class="tb_div_xia">
                <a>
                    <div class="huang_yuan">
                        <img class="huang_yuan_tubiao" src="../../assets/images/telegram.png"/>
                        <img src="../../assets/images/united-states.png"/>
                    </div>
                </a>
                <a>
                    <div class="huang_yuan">
                        <img class="huang_yuan_tubiao" src="../../assets/images/telegram.png"/>
                        <img src="../../assets/images/turkey.png"/>
                    </div>
                </a>
                <a>
                    <div class="huang_yuan">
                        <img class="huang_yuan_tubiao" src="../../assets/images/telegram.png"/>
                        <img src="../../assets/images/russia.png"/>
                    </div>
                </a>
            </div>
        </div>

        <div class="bottom_text">
            <div class="tetx_bot">
                <div class="bot_left_text">
                    © 2021 TrustFi Network
                </div>
                <div class="bot_right_text">
                    Privacy — Terms
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Bottom"
    }
</script>

<style lang="less">
    .frames_bottom {
        z-index: 99;
        margin: 0 100px;
        zoom: 0.9;

        /*position: absolute;*/
        /*bottom: 0;*/
        height: 300px;
        width: auto;
        min-width: 1080px;
        color: #FFFFFF;
        background: black;
        border-top: 5px solid #FEE108;
        .bottom_tfi {
            width: 240px;
            margin: 0 auto;
            margin-top: 10px;
            text-align: center;
            .tfi_div_img {
                img {
                    width: 240px;
                    height: 75px;
                }
                .tfi_div_text {
                    width: 60px;
                    height: 18px;
                    font-size: 14px;
                    color: #1D1D1D;
                    font-weight: 600;
                    line-height: 18px;
                    background: #FEE108;
                    border-radius: 2px;
                    margin-top: -12px;
                    margin-left: 172px;
                }
            }
        }
        .bottom_tb_div {
            margin: 0 auto;
            width: 200px;
            .tb_div_shang {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-around;
                img {
                    cursor: pointer;
                }
                .huang_yuan_tubiao_a {
                    margin: 10px;
                }
                .huang_yuan_tubiao_b {
                    margin: 10px -8px 10px 20px !important;
                }
            }
            .tb_div_xia {
                margin-top: 5px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-around;
                img {
                    cursor: pointer;
                }
            }
            .huang_yuan {
                width: 53px;
                height: 53px;
                background-color: #FEE108;
                border-radius: 50%;
                display: flex;
                align-items: flex-end;
                justify-content: space-around;
                .huang_yuan_tubiao {
                    margin: 8px 0 10px 12px;
                }
            }
        }
        .bottom_text {
            margin-top: 25px;
            .tetx_bot {
                margin: 0 auto;
                width: 500px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                .bot_left_text {}
                .bot_right_text {}
            }
        }
    }
    @import '../../global.less';
</style>
