var mixin1 = {
    computed: {
        isFollow() {
            return this.$store.state.accounts[0]; // 需要监听的数据
        }
    },
    watch: {
        isFollow(newVal, oldVal) {
            console.log("newVal", newVal, "oldVal", oldVal);
            if (newVal === undefined) {
                localStorage.setItem('accounts', '');
            } else {
                localStorage.setItem('accounts', newVal);
            }
            var _this = this;
            _this.refreshData(newVal);
        }
    },
    methods: {
        onAccountsChanged: function () {
            var _this = this
            window.ethereum.on('accountsChanged', function (accounts) {
                _this.onRefresh(accounts)
            })
        }
    }
}

export {mixin1}
