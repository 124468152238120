<template>
  <div class="buy-tfi-btn" @click="showSubButtons = !showSubButtons">
    <template v-if="showSubButtons">
      <div
              class="sub-button"
              @click="
              openUrl(
                'https://pancakeswap.finance/swap?outputCurrency=0x7565ab68d3f9dadff127f864103c8c706cf28235'
              )
            "
      >
        <img src="../../assets/images/footer/dealer_icon_1.png" height="23"/>
        <div>TFI/BNB</div>
      </div>
      <div
              class="sub-button"
              style="bottom: calc(-63px * 2 - 6px)"
              @click="openUrl('https://www.mexc.com/exchange/TRUSTFI_USDT')"
      >
        <img src="../../assets/images/footer/dealer_icon_2.png" height="21"/>
        <div>TRUSTFI/USDT</div>
      </div>
      <div
              class="sub-button"
              style="bottom: calc(-63px * 3 - 8px); padding-top: 4px"
              @click="openUrl('https://www.hoo.com/spot/tfi-usdt')"
      >
        <img src="../../assets/images/footer/dealer_icon_3.png" height="30"/>
        <div>TFI/USDT</div>
      </div>
    </template>
    Buy $TFI
  </div>
</template>

<script>
  export default {
    name: "BuyTfiBtn",
    data() {
      return {
        showSubButtons: false
      }
    },
    mounted() {
      window.addEventListener("click", this.hideDropDown, true);
    },
    beforeDestroy() {
      window.removeEventListener("click", this.hideDropDown, true);
    },
    methods: {
      openUrl(url) {
        window.open(url)
      },
      hideDropDown() {
        this.showSubButtons = false;
      },
    }
  }
</script>

<style scoped lang="less">
  .buy-tfi-btn {
    position: relative;
    cursor: pointer;
    width: 190px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    background: #3CD63C;
    border: 1px solid #83DB5C;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    .sub-button {
      position: absolute;
      bottom: calc(-63px - 4px);
      left: 50%;
      transform: translateX(-50%);
      width: 250px;
      height: 63px;
      background: #ffffff;
      border: 2px solid #fee108;
      box-sizing: border-box;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      text-align: center;
      padding-top: 8px;
      z-index: 11;
    }
  }
</style>
